<template>
  <div
    class="v-application v-application--is-ltr theme--light menuable__content__active"
  >
    <!--begin::Attendance-->
    <div class="row">
      <div class="col-xxl-12 col-12 col-md-12">
        <b-card>
          <div class="row justify-content-between">
            <div class="col-md-1">
              <b-form-group class="mb-0">
                <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"
                ></b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-3">
              <b-form-group class="mb-0">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text>
                      <i class="menu-icon flaticon-search"></i>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    v-model="search"
                    placeholder="Search"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </div>
          </div>
          <div class="table-responsive">
            <b-table
              hover
              :items="tableData"
              :fields="columns"
              :per-page="perPage"
              :current-page="currentPage"
              :busy="isLoading"
              show-empty
            >
              <template #cell(created_at)="row">
                <span class="mr-2">
                  {{ dateToISOString(row.item.created_at) }}
                </span>
              </template>
              <template #cell(check_in)="row">
                <span class="mr-2">
                  {{ timeFormat(row.item.check_in) }}
                </span>
              </template>
              <template #cell(check_out)="row">
                <span class="mr-2">
                  {{ timeFormat(row.item.check_out) }}
                </span>
              </template>
              <template #cell(status)="row">
                <span class="mr-2">
                  {{ statusName(row.item.status) }}
                </span>
              </template>
              <template #cell(range)="row">
                <span class="mr-2">
                  <b-badge
                    :variant="
                      row.item.range === 'In Range' ? 'success' : 'danger'
                    "
                    >{{ row.item.range }}</b-badge
                  >
                </span>
              </template>
              <template #cell(action)="row">
                <span class="mr-2" @click="handleView(row.item)">
                  <b-button
                    variant="primary"
                    class="py-1 px-2"
                    id="detail"
                    title="detail"
                  >
                    <i class="menu-icon flaticon-eye pr-0"></i>
                  </b-button>
                </span>
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-2"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
            </b-table>
          </div>
          <div class="row justify-content-end">
            <div class="col-md-3">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                size="md"
                align="fill"
                class="ml-auto"
                style="padding: 0"
              ></b-pagination>
            </div>
          </div>
        </b-card>
      </div>
    </div>
    <!--end::Attendance-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { sortChar, dateToISOString, timeFormat } from "@/utils/helper";
import { debounce } from "debounce";

export default {
  name: "ListAttendance",
  data() {
    return {
      // filterCustomer: [],
      // selected: {
      //   customer: null,
      //   start: null,
      //   end: null
      // },
      isLoading: false,
      rowStyleOption: {
        stripe: true,
      },
      search: "",
      pageIndex: 1,
      totalRows: 10,
      currentPage: 1,
      pageSize: 10,
      perPage: 10,
      pageOptions: [10, 15, { value: 100, text: "Show a lot" }],
      sortOption: {
        sortChange: (params) => {
          this.sortChange(params);
        },
      },
      columns: [
        {
          key: "employee.nip",
          label: "NIP",
          align: "left",
          sortBy: "",
          width: "",
          sortable: true,
        },
        {
          key: "employee.name",
          label: "Name",
          align: "left",
          sortBy: "",
          width: "",
          sortable: true,
        },
        {
          key: "employee.customer_branch.name",
          label: "Customer",
          align: "left",
          sortBy: "",
          width: "",
          sortable: true,
        },
        {
          key: "employee.shift.name",
          label: "Shift",
          align: "left",
          sortBy: "",
          width: "",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Date",
          align: "left",
          sortBy: "",
          width: "",
          sortable: true,
        },
        {
          key: "check_in",
          label: "Check In",
          align: "left",
          sortBy: "",
          width: "",
          sortable: true,
        },
        {
          key: "check_out",
          label: "Check Out",
          align: "left",
          sortBy: "",
          width: "",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          align: "left",
          sortBy: "",
          width: "",
          sortable: true,
        },
        {
          key: "check_in_range",
          label: "Check In Radius",
          align: "left",
          sortBy: "",
          width: "",
          sortable: true,
        },
        {
          key: "check_out_range",
          label: "Check Out Radius",
          align: "left",
          sortBy: "",
          width: "",
          sortable: true,
        },
      ],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      listTable: [],
      filter: null,
      filterOn: [],
      params: {
        query: "",
        sort: "created_at",
        order: "desc",
        limit: 10,
        offset: 0,
      },
      timer: 0,
    };
  },
  components: {},
  computed: {
    tableData() {
      return this.listTable;
    },
    totalCount() {
      return this.listTable.length;
    },
    refPerpage: {
      get() {
        return this.perPage;
      },
      set(value) {
        this.perPage = value;
        this.params.limit = value;
      },
    },
    refSortBy: {
      get() {
        return this.sortBy;
      },
      set(value) {
        this.sortBy = value;
        this.params.sort = value;
      },
    },
    refSortDirectionChange: {
      get() {
        return this.sortDirection;
      },
      set(value) {
        this.sortDirection = value;
        this.params.order = value;
      },
    },

    sortOptions() {
      return this.columns
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    this.getTabel();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Absensi", route: "/report/absensi" },
      { title: "Detail" },
    ]);
  },
  methods: {
    sortChange(params) {
      Object.entries(params).forEach((item) => {
        if (item[1]) {
          // eslint-disable-next-line
          console.log(item[0], item[1]);
        }
      });
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    },
    async getTabel() {
      this.isLoading = true;
      try {
        const customerBranchId = this.$route.params.id;
        await this.$axios
          .get(
            `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/presence/detail/${customerBranchId}/${this.$route.params.date}/${this.$route.params.date}`,
            {
              params: this.params,
            }
          )
          .then(({ data }) => {
            this.listTable = data.list;
            this.totalRows = data.total;
          });
      } catch ({ response }) {
        this.$bvToast.toast(response.data.message ?? "Terjadi Kesalahan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isLoading = false;
      }
    },
    statusName(id) {
      if (id === 1) return "Hadir";
      else if (id === 2) return "Izin";
      else if (id === 3) return "Sakit";
      else if (id === 4) return "Alfa";
      else if (id === 5) return "Cuti";
      else if (id === 6) return "Pengganti";
      else "Absen";
    },
    processChange: debounce(function () {
      this.getTabel();
    }, 500),
    // onCopy(e) {
    //   this.$bvToast.toast(e.text, {
    //     title: "Successfuly Copy to clipboard",
    //     variant: "success",
    //     solid: true,
    //   });
    // },
    sortChar,
    dateToISOString,
    timeFormat,
  },
};
</script>
